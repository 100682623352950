import styled from 'styled-components';

import { cssBreakpoints } from 'theme/theme';

export const TruncatedText = styled.span`
  text-overflow: ellipsis;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -moz-line-clamp: 3;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    min-height: 70px;
  }
`;
