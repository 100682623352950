import styled from 'styled-components';

import { cssBreakpoints } from 'theme/theme';

export const currentPageVar = '--current-page';
export const gutterVar = '--gutter';

const slidesPerPageVar = '--slides-per-page';
export const slidesPerPageXsVar = `--slides-per-page-xs`;
export const slidesPerPageSmVar = `${slidesPerPageVar}-sm`;
export const slidesPerPageMdVar = `${slidesPerPageVar}-md`;
export const slidesPerPageLgVar = `${slidesPerPageVar}-lg`;

export const indexVar = '--index';
const nthIndexVar = '--nth-index';
export const nthIndexXsVar = '--nth-index-xs';
export const nthIndexSmVar = '--nth-index-sm';
export const nthIndexMdVar = '--nth-index-md';
export const nthIndexLgVar = '--nth-index-lg';

export const CarouselHolder = styled.div`
  position: relative;
`;

/**
 * The purpose of this div is to set `overflow: hidden` around the slider items
 * but not around the navigation.
 */
export const CarouselSliderHolder = styled.div`
  margin-bottom: 30px;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const CarouselSlider = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  will-change: transform;
  transform: translateX(calc(100% * var(${currentPageVar}) * -1));
`;

export const CarouselSlide = styled.div`
  position: absolute;
  width: calc(
    100% / var(${slidesPerPageVar}) - (var(${slidesPerPageVar}) - 1) *
      var(${gutterVar})
  );
  height: 100%;
  left: calc(
    100% * var(${indexVar}) / var(${slidesPerPageVar}) + var(${gutterVar}) *
      var(${nthIndexVar})
  );
  overflow: hidden;

  ${nthIndexVar}: var(${nthIndexXsVar});
  ${slidesPerPageVar}: var(${slidesPerPageXsVar});

  @media all and (min-width: ${cssBreakpoints.smUp}) {
    ${nthIndexVar}: var(${nthIndexSmVar});
    ${slidesPerPageVar}: var(${slidesPerPageSmVar});
  }

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    ${nthIndexVar}: var(${nthIndexMdVar});
    ${slidesPerPageVar}: var(${slidesPerPageMdVar});
  }

  @media all and (min-width: ${cssBreakpoints.lgUp}) {
    ${nthIndexVar}: var(${nthIndexLgVar});
    ${slidesPerPageVar}: var(${slidesPerPageLgVar});
  }
`;
