import styled, { css } from 'styled-components';

import { colors, transition } from 'theme/theme';

const insetCss = css<{ $showDropShadow?: boolean; $buttonColor?: string }>`
  left: 0;
  transform: translateY(-50%);

  button {
    background: transparent;
    appearance: none;
    border: 0;
    padding: 0;
    pointer-events: all;
    color: ${(props) => props.$buttonColor || colors.brandBlue};
    ${(props) =>
      props.$showDropShadow &&
      `filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.35));`}

    &[disabled] {
      opacity: 0.33;
    }
  }
`;

const outsetCss = css`
  left: 50%;
  transform: translate(-50%, -50%);

  button {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 0;
    justify-content: center;
    align-items: center;
    color: white;
    background: ${colors.brandBlue};
    border-radius: 50%;
    pointer-events: auto;
    transition: all ${transition};

    &[disabled] {
      opacity: 0.33;

      &:hover {
        background: ${colors.brandBlue};
      }
    }

    &:hover,
    &:focus {
      background: ${colors.brandGreen};
    }
  }
`;

export const ArrowNavigationContainer = styled.div<{
  $width?: string;
  $outset?: boolean;
  $showDropShadow?: boolean;
  $buttonColor?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  width: ${(props) => (props.$outset ? 'calc(100% + 128px)' : '100%')};
  max-width: calc(100vw - 16px);
  pointer-events: none;

  ${(props) => (props.$outset ? outsetCss : insetCss)}
`;
