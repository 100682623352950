import { Icon } from 'components/Icon/Icon';
import { LegacyButton } from 'components/LegacyButton/LegacyButton';

import { ArrowNavigationContainer } from './ArrowNavigation.styled';

type Props = {
  nextPage: () => void;
  prevPage: () => void;
  current: number;
  total: number;
  outset?: boolean;
  showDropShadow?: boolean;
  buttonColor?: string;
};

export function ArrowNavigation({
  nextPage,
  prevPage,
  current,
  total,
  outset,
  showDropShadow,
  buttonColor,
}: Props) {
  const title = {
    prev: getText('Previous page'),
    next: getText('Next page'),
  };

  const prevDisabled = current <= 0;
  const nextDisabled = current >= total - 1;
  return (
    <ArrowNavigationContainer
      $outset={outset}
      $showDropShadow={showDropShadow}
      $buttonColor={buttonColor}
    >
      <LegacyButton
        onClick={prevPage}
        aria-label={title.prev}
        title={title.prev}
        disabled={prevDisabled}
        data-qa-id="carousel-left-button"
      >
        <Icon name="chevron-left" size={24} />
      </LegacyButton>

      <LegacyButton
        onClick={nextPage}
        aria-label={title.next}
        title={title.next}
        disabled={nextDisabled}
        data-qa-id="carousel-right-button"
      >
        <Icon name="chevron-right" size={24} />
      </LegacyButton>
    </ArrowNavigationContainer>
  );
}
