import styled from 'styled-components';

import { cssBreakpoints, linkStyles } from 'theme/theme';

export const TileCarouselHolder = styled.div<{
  $layout: 'SLIM' | 'REGULAR';
}>`
  margin: 0 auto ${({ $layout }) => ($layout === 'SLIM' ? '60px' : '40px')};
  max-width: 250px;
  padding: 0 12px;
  width: auto;

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    max-width: 718px;
  }

  @media all and (min-width: ${cssBreakpoints.lgUp}) {
    margin-left: -24px;
    margin-right: -24px;
    max-width: none;
    padding: 0 20px;
  }
`;

export const TileCarouselSeeAllButton = styled.button`
  ${linkStyles};
`;
