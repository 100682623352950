import { CSSProperties, ReactNode, useCallback } from 'react';
import Hammer from 'react-hammerjs';

import {
  CarouselHolder,
  CarouselSlide,
  CarouselSlider,
  CarouselSliderHolder,
  currentPageVar,
  gutterVar,
  indexVar,
  nthIndexLgVar,
  nthIndexMdVar,
  nthIndexSmVar,
  nthIndexXsVar,
  slidesPerPageLgVar,
  slidesPerPageMdVar,
  slidesPerPageSmVar,
  slidesPerPageXsVar,
} from './Carousel.styled';
import { CarouselState } from './CarouselState';

type Props = {
  id?: string;
  state: CarouselState;
  slides: ReactNode[];
  navigation?: ReactNode;
  gutter?: number;
  height: number;
  'data-qa-id': string;
};

export function Carousel({
  id,
  state,
  'data-qa-id': qaId,
  slides,
  gutter = 8,
  navigation,
  height,
}: Props) {
  const {
    currentPage,
    responsiveSlidesPerPage,
    goToPreviousPage,
    goToNextPage,
  } = state;

  const swipeHandler = useCallback(
    ({ direction }: { direction: number }) => {
      if (direction === 2) goToNextPage();
      else if (direction === 4) goToPreviousPage();
    },
    [goToNextPage, goToPreviousPage],
  );

  return (
    <CarouselHolder id={id} data-qa-id={qaId} style={{ height }}>
      <Hammer onSwipe={swipeHandler}>
        <CarouselSliderHolder>
          <CarouselSlider
            data-qa-slide-count={slides.length}
            data-qa-id={`${qaId}-slider`}
            style={
              {
                [currentPageVar]: currentPage,
                [gutterVar]: `${gutter}px`,
                [slidesPerPageXsVar]: responsiveSlidesPerPage.xs,
                [slidesPerPageSmVar]: responsiveSlidesPerPage.sm,
                [slidesPerPageMdVar]: responsiveSlidesPerPage.md,
                [slidesPerPageLgVar]: responsiveSlidesPerPage.lg,
              } as CSSProperties
            }
          >
            {slides.map((slide, index) => (
              <CarouselSlide
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                data-qa-id={`${qaId}-slide-${index}`}
                style={
                  {
                    [indexVar]: index,
                    [nthIndexXsVar]: index % responsiveSlidesPerPage.xs,
                    [nthIndexSmVar]: index % responsiveSlidesPerPage.sm,
                    [nthIndexMdVar]: index % responsiveSlidesPerPage.md,
                    [nthIndexLgVar]: index % responsiveSlidesPerPage.lg,
                  } as CSSProperties
                }
              >
                {slide}
              </CarouselSlide>
            ))}
          </CarouselSlider>
        </CarouselSliderHolder>
      </Hammer>

      {navigation}
    </CarouselHolder>
  );
}
