import { ArrowNavigation } from 'components/ArrowNavigation/ArrowNavigation';
import { CarouselState } from 'components/Carousel/CarouselState';
import { DotNavigationButton } from 'components/DotNavigationButton/DotNavigationButton';
import { range } from 'utils/functional/array/range';

import {
  TileCarouselNavigationDotsInnerHolder,
  TileCarouselNavigationDotsOuterHolder,
} from './TileCarouselNavigation.styled';

type Props = {
  carouselState: CarouselState;
};

export function TileCarouselNavigation({ carouselState }: Props) {
  const { currentPage, pageCount, goToPage, goToPreviousPage, goToNextPage } =
    carouselState;

  return (
    <nav>
      <ArrowNavigation
        nextPage={goToNextPage}
        prevPage={goToPreviousPage}
        current={currentPage}
        total={pageCount}
        outset
      />

      {pageCount > 1 && (
        <TileCarouselNavigationDotsOuterHolder>
          <TileCarouselNavigationDotsInnerHolder>
            {range(pageCount).map((index) => (
              <DotNavigationButton
                key={index}
                onClick={() => goToPage(index)}
                $isActive={index === currentPage}
                title={`${getText('Go to page')} ${index + 1}`}
              />
            ))}
          </TileCarouselNavigationDotsInnerHolder>
        </TileCarouselNavigationDotsOuterHolder>
      )}
    </nav>
  );
}
