import styled from 'styled-components';

import { SmallTile } from 'components/Tile/SmallTile';
import { colors, cssBreakpoints, transition } from 'theme/theme';

export const RegularSearchTile = styled(SmallTile)`
  padding: 20px;
  width: 100%;
  height: 330px;

  @media all and (max-width: ${cssBreakpoints.mdDown}) {
    height: 310px;
  }
`;

export const SlimSearchTile = styled(SmallTile)`
  overflow: visible;
  cursor: pointer;
  border-top: 4px solid ${colors.brandBlue};
  transition: all ${transition};

  display: block;
  padding: 20px 36px;
  width: 100%;
  height: 200px;

  &:hover,
  &:focus {
    border-color: ${colors.brandGreen};
  }
`;
