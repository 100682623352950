import styled from 'styled-components';

export const TileCarouselNavigationDotsOuterHolder = styled.div`
  position: relative;
`;

export const TileCarouselNavigationDotsInnerHolder = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  max-width: calc(100vw - 16px);
  gap: 4px;
`;
