import { MouseEvent, ReactNode } from 'react';

import { RegularSearchTile, SlimSearchTile } from './SlimSearchTile';

type SectionSearchTileProps = {
  layout: 'SLIM' | 'REGULAR';
  children: ReactNode;
  href: string;
  onClick: (event: MouseEvent<HTMLElement>) => void;
};

export function SectionSearchTile({
  layout,
  children,
  href,
  onClick,
}: SectionSearchTileProps) {
  if (layout === 'REGULAR') {
    return (
      <RegularSearchTile
        withHoverEffect
        type="link"
        href={href}
        onClick={onClick}
      >
        {children}
      </RegularSearchTile>
    );
  }

  return (
    <SlimSearchTile type="link" href={href} onClick={onClick}>
      {children}
    </SlimSearchTile>
  );
}
