import { ReactNode } from 'react';
import styled from 'styled-components';

import { Box } from 'components/Box/Box';
import { BodySmall } from 'components/Text/BodySmall';
import { RichTextHeadline } from 'components/Text/RichTextHeadline';

type HeaderProps = {
  layout: 'SLIM' | 'REGULAR';
  headline: string | null | undefined;
  linkNode: ReactNode | null | undefined;
};
const SlimRichTextHeadline = styled(RichTextHeadline)`
  h2 {
    margin: 0 !important;
  }

  h2::before {
    display: none !important;
  }
`;

export function TileCarouselHeader({
  layout,
  headline,
  linkNode,
}: HeaderProps) {
  if (layout === 'REGULAR')
    return (
      <>
        {headline && (
          <RichTextHeadline textAlignCenter>{headline}</RichTextHeadline>
        )}

        {linkNode && (
          <BodySmall textAlign={['center', null, null, 'right']} mb={20}>
            {linkNode}
          </BodySmall>
        )}
      </>
    );

  return (
    <Box
      display="flex"
      flexDirection={['column', 'column', 'row', 'row']}
      justifyContent={[null, null, 'space-between', 'space-between']}
      alignItems={['start', 'start', 'end', 'end']}
      gridGap={8}
      mb={48}
    >
      {headline && <SlimRichTextHeadline>{headline}</SlimRichTextHeadline>}

      <BodySmall>{linkNode}</BodySmall>
    </Box>
  );
}
