import invariant from 'invariant';
import { useMemo } from 'react';

import { ViewportBreakpoint } from 'theme/theme';
import { identityFilter } from 'utils/functional/function';
import { viewportBreakpointArray } from 'utils/viewport/getViewportBreakpoint';

import { useViewportBreakpoint } from './useViewportBreakpoint';

export function useViewportBreakpointValue<T>(
  values: Partial<Record<ViewportBreakpoint, T>>,
): T {
  const valuesArray = useMemo(
    () => [values.xs, values.sm, values.md, values.lg],
    [values.xs, values.sm, values.md, values.lg],
  );

  invariant(
    valuesArray.filter(identityFilter).length > 0,
    'Attempting to get viewport value without providing any breakpoint value',
  );

  const viewportBreakpoint = useViewportBreakpoint();

  return useMemo(() => {
    const viewportBreakpointIndex =
      viewportBreakpointArray.indexOf(viewportBreakpoint);

    for (let i = viewportBreakpointIndex; i >= 0; i--) {
      const value = valuesArray[i];
      if (value !== undefined) return value;
    }

    return valuesArray.filter(identityFilter)[0];
  }, [viewportBreakpoint, valuesArray]);
}
