import mapValues from 'lodash/mapValues';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useIsClient } from 'hooks/useIsClient';
import { useViewportBreakpointValue } from 'hooks/viewport/useViewportBreakpointValue';
import { ViewportBreakpoint } from 'theme/theme';

import { CarouselState } from './CarouselState';

type Props = {
  slideCount: number;
  responsiveSlidesPerPage: Record<ViewportBreakpoint, number>;
  capSlidesPerPageToSlideCount?: boolean;
};

export function useCarouselState({
  slideCount,
  responsiveSlidesPerPage,
  capSlidesPerPageToSlideCount,
}: Props): CarouselState {
  const [currentPage, setCurrentPage] = useState(0);

  const cappedResponsiveSlidesPerPage = useMemo(() => {
    if (!capSlidesPerPageToSlideCount) return responsiveSlidesPerPage;

    return mapValues(responsiveSlidesPerPage, (value) =>
      value > slideCount ? slideCount : value,
    );
  }, [capSlidesPerPageToSlideCount, responsiveSlidesPerPage, slideCount]);

  const slidesPerPage = useViewportBreakpointValue(
    cappedResponsiveSlidesPerPage,
  );
  const isClient = useIsClient();

  const pageCount = useMemo(
    () => Math.ceil(slideCount / slidesPerPage),
    [slideCount, slidesPerPage],
  );

  const hasPreviousPage = isClient && currentPage > 0;
  const hasNextPage = isClient && currentPage < pageCount - 1;

  const goToPage = useCallback(
    (index: number) => {
      const max = pageCount - 1;

      let indexWithinRange = index;
      if (index < 0) indexWithinRange = 0;
      if (index > max) indexWithinRange = max;

      setCurrentPage(indexWithinRange);
    },
    [pageCount],
  );

  const goToNextPage = useCallback(
    () => goToPage(currentPage + 1),
    [currentPage, goToPage],
  );

  const goToPreviousPage = useCallback(
    () => goToPage(currentPage - 1),
    [currentPage, goToPage],
  );

  // Set current page to the last in case the number of pages changed and the
  // current page does not exist
  useEffect(() => {
    if (currentPage > pageCount - 1 && pageCount > 0) {
      setCurrentPage(pageCount - 1);
    }
  }, [currentPage, pageCount]);

  return useMemo(
    () => ({
      pageCount,
      slideCount,
      responsiveSlidesPerPage: {
        xs: cappedResponsiveSlidesPerPage.xs,
        sm: cappedResponsiveSlidesPerPage.sm,
        md: cappedResponsiveSlidesPerPage.md,
        lg: cappedResponsiveSlidesPerPage.lg,
      },

      currentPage,
      goToPage,

      hasPreviousPage,
      goToPreviousPage,

      hasNextPage,
      goToNextPage,
    }),
    [
      cappedResponsiveSlidesPerPage.lg,
      cappedResponsiveSlidesPerPage.md,
      cappedResponsiveSlidesPerPage.sm,
      cappedResponsiveSlidesPerPage.xs,
      currentPage,
      goToNextPage,
      goToPage,
      goToPreviousPage,
      hasNextPage,
      hasPreviousPage,
      pageCount,
      slideCount,
    ],
  );
}
