import styled from 'styled-components';

import { colors, transition } from 'theme/theme';

export const DotNavigationButton = styled.button<{ $isActive?: boolean }>`
  width: 12px;
  height: 12px;
  border-radius: 100%;
  appearance: none;
  border: 1px solid ${colors.brandBlue};
  transition: all ${transition};
  background: ${(props) =>
    props.$isActive ? colors.brandBlue : 'transparent'};
`;
